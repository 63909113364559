import "./tips.scss";

import * as React from "react";

const Tips: React.FC = () => {
  return (
    <section className={"o-tips"}>
      <div className={"grid-x3 grid-x3--break-tablet"}>
        <div className={"m-card m-card--grey m-card--no-shadow"}>
          <h2 className={"h3"}>Contra el dolor</h2>
          <p>
            La fisioterapia es una disciplina enfocada en el tratamiento del
            dolor físico. El ritmo de vida en la urbe, el estrés, la
            alimentación, la falta de descanso… Un cocktail perfecto para la
            aparición de dolencias físicas constantes.
            <br />
            Contra el dolor, las distintas disciplinas de la fisioterapia
            moderna, son el método más efectivo para una pronta recuperación y
            vuelta a la mejor funcionalidad de nuestro cuerpo.
          </p>
        </div>
        <div className={"m-card m-card--dark m-card--no-shadow"}>
          <h2 className={"h3"}>Recupérate</h2>
          <p>
            Nuestra experiencia como fisioterapeutas nos dice que muchas
            personas se resignan a vivir con sus dolencias, privándoles de
            realizar actividades que acostumbraban a hacer.
            <br />
            La fisioterapia ayuda a identificar el por qué de la aparición del
            dolor y a poner en práctica una terapia para mejorarlo y
            eventualmente eliminarlo. ¡Recupérate y camina hacia tu mejor
            versión!
          </p>
        </div>
        <div className={"m-card m-card--grey m-card--no-shadow"}>
          <h2 className={"h3"}>Un problema común</h2>
          <p>
            Todos padeceremos lesiones a lo largo de nuestra vida. Es importante
            que nos asesoremos correctamente por los fisioterapeutas adecuados,
            que nos mostrarán el camino a seguir para una pronta recuperación.
            <br />
            En Obelion disponemos de múltiples técnicas de diagnóstico. Podemos
            determinar de un modo científico cuál es el alcance de la lesión y
            comenzar a tratarla desde ya.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Tips;
