import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import Helmet from "react-helmet";

import {
  address,
  contact,
  defaultDescription,
  legalName,
  name,
  logo,
  socialLinks,
  url,
} from "../../data/config";

const HomeMarkupSchema: React.FC = () => {
  const data = useStaticQuery(
    graphql`
      query RatesHomeSchemaQuery {
        allGooglePlacesPlace {
          nodes {
            user_ratings_total
            rating
          }
        }
      }
    `
  );
  const markup = `{ 
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "${name}",
    "alternateName": "Obelion Fisioterapeutas",
    "url": "${url}",
    "logo": "${logo}",
    "contactPoint": {
      "@type": "ContactPoint",
      "email": "${contact.email}",
      "telephone": "${contact.phone}",
      "contactType": "customer service",
      "areaServed": "ES",
      "availableLanguage": "ES"
    },
    "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "${data.allGooglePlacesPlace.nodes[0].rating}",
        "bestRating": "5",
        "worstRating": "1",
        "ratingCount": "${data.allGooglePlacesPlace.nodes[0].user_ratings_total}"
      },
    "sameAs": [
      "${socialLinks.facebook}",
      "${socialLinks.twitter}",
      "${socialLinks.instagram}"
    ]
    }
  }`;
  const professionalServiceMarkup = `
{
 "@context": "https://schema.org",
  "@type": "Physiotherapy",
  "name": "${legalName}",
  "description": "${defaultDescription}",
  "image": "${logo}",
  "@id": "${url}",
  "url": "${url}",
  "telephone": "${contact.phone}",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "${address.streetAddress}",
    "addressLocality": "${address.city}",
    "postalCode": "${address.zipCode}",
    "addressCountry": "ES"
  },
  "openingHoursSpecification": {
    "@type": "OpeningHoursSpecification",
    "dayOfWeek": [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday"
    ],
    "opens": "11:00",
    "closes": "20:30"
  },
  "department": [{
    "@type": "Physiotherapy",
    "name": "Ecografía fisioterapia en Zaragoza",
    "url": "${url}ecografia/"
  },{
    "@type": "Physiotherapy",
    "name": "Técnica de punción seca en Zaragoza",
    "url": "${url}puncion-seca/" 
  },{
    "@type": "Physiotherapy",
    "name": "Fisioterapia invasiva ecoguiada con EPI en Zaragoza",
    "url": "${url}epi/"
  },{
    "@type": "Physiotherapy",
    "name": "Terapia manual avanzada",
    "url": "${url}terapia-manual-avanzada/"
  },{
      "@type": "Physiotherapy",
    "name": "Tratamiento Fisioterapia de suelo pélvico en Zaragoza",
    "url": "${url}suelo-pelvico/"
  },{  
    "@type": "Physiotherapy",
    "name": "Tratamiento INDIBA radiofrecuencia en Zaragoza",
    "url": "${url}indiba/"
  },{
    "@type": "Physiotherapy",
    "name": "Fisioterapia Activa con control motor en Zaragoza",
    "url": "${url}ejercicios-control-motor/"
  },{
    "@type": "ProfessionalService",
    "name": "Servicio de acompañamiento en el embarazo, ejercicio y postparto",
    "url": "${url}embarazo/"
  },{
    "@type": "ExerciseGym",
    "name": "Personal training Zaragoza",
    "url": "${url}personal-training/"
  },{  
    "@type": "ExerciseGym",
    "name": "Pilates en Zaragoza",
    "url": "${url}pilates/"
  },{
    "@type": "ExerciseGym",
    "name": "Entrenamientos grupales",
    "url": "${url}training/"
  }]

}
  	`;
  return (
    <Helmet>
      <script type="application/ld+json">{markup}</script>
      <script type="application/ld+json">{professionalServiceMarkup}</script>
    </Helmet>
  );
};

export default HomeMarkupSchema;
