import "./service-resume.scss";

import { Link } from "gatsby";
import * as React from "react";
import { PropsWithChildren } from "react";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import pilates from "../../../assets/images/pilates.jpg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import training from "../../../assets/images/training.jpg";
import { OtherService } from "../../../contexts/othersServices/domain/OtherService";
import { Training } from "../../../contexts/training/domain/training";

interface PropsResume extends PropsWithChildren<any> {
  trainings: Training[];
  otherServices: OtherService[];
}

const ServiceResume: React.FC<PropsResume> = (props: PropsResume) => {
  const buttons_training = [];
  props.trainings.forEach((training) => {
    buttons_training.push(
      <Link
        key={training.slug}
        className={"a-button a-button--border a-button--border__blue"}
        to={`/${training.slug}/`}
      >
        {training.shortTitle}
      </Link>
    );
  });

  const buttons_others = [];
  props.otherServices.forEach((others) => {
    buttons_others.push(
      <Link
        key={others.slug}
        className={"a-button a-button--border a-button--border__blue"}
        to={`/${others.slug}/`}
      >
        {others.shortTitle}
      </Link>
    );
  });

  return (
    <div className={"o-service-resume container"}>
      <div
        className={
          "o-box o-box--blue is-border-top-right-desktop is-border-top-right-mobile grid-x2 grid-x2--break-pro"
        }
      >
        <div
          className={"a-box__image is-border-top-right-mobile"}
          style={{
            backgroundImage: `url(${training})`,
            backgroundPosition: "center",
          }}
        />
        <div className={"m-box is-border-top-right-desktop"}>
          <h3>Training</h3>
          <p className={"m-box__text"}>
            El entrenamiento físico estan necesario como indelegable.
Entrenadores personales, entrenamientos en grupo y clases de pilates. Te ayudamos a
alcanzar tus metas y sentirte mejor cada día. Nadie puede hacerlo por ti.
          </p>
          <div className={"m-service-resume__buttons"}>{buttons_training}</div>
        </div>
      </div>
      <div
        className={
          "o-box o-box--orange is-border-bottom-left-desktop is-border-bottom-left-mobile grid-x2 grid-x2--break-pro"
        }
      >
        <div
          className={
            "m-box is-border-bottom-left-desktop is-border-bottom-left-mobile"
          }
        >
          <h3>Más servicios</h3>
          <p className={"m-box__text"}>
            En Obelion disponemos de servicios paralelos a la fisioterapia y el ejercicio. Siempre de la
mano de profesionales que se mantienen a la vanguardia de susrespectivossectores. Porque
detenerse, es no avanzar; permanecemos en constante aprendizaje y evolución.
          </p>
          <div className={"m-service-resume__buttons"}>{buttons_others}</div>
        </div>
        <div
          className={"a-box__image box-revert-responsive"}
          style={{
            backgroundImage: `url(${pilates})`,
            backgroundPosition: "center",
          }}
        />
      </div>
    </div>
  );
};

export default ServiceResume;
