
export const getTrainingMapped = (sanityTraining) => {
  const trainers = getTrainers(sanityTraining.trainers);
  const seo = setSeo(sanityTraining);
  const list = setList(sanityTraining)
  const packs = getPacks(sanityTraining)
  return {
    background: sanityTraining.hero.background,
    background_alt: sanityTraining.hero.background_alt,
    capacity: sanityTraining.capacity,
    color: sanityTraining.color,
    simplybookId: sanityTraining.simplybookId,
    level: sanityTraining.level,
    list,
    minutes: sanityTraining.minutes,
    shortTitle: sanityTraining.card.title,
    subtitle: sanityTraining.hero.subtitle,
    id: sanityTraining._id,
    title: sanityTraining.hero.title,
    seo,
    slug: sanityTraining.slug.current,
    trainers,
    photo: sanityTraining.card.photo,
    photo_alt: sanityTraining.card.photo_alt,
    content: sanityTraining.card._rawContent,
    packs,
  };
};

function setList(data){
  if(data.enumList){
    return {
      list: data.enumList
    }
  } else {
    return { list: null };
  }
}

function setSeo(data){
  if(data.seo){
    return {
      title: data.seo.title,
      description: data.seo.desc,
      canonicals: data.seo.canonical,
      robots: data.seo.seo_robots,
    }
  }else{
    return {
      title: data.hero.title,
      description: data.hero.subtitle,
      canonicals: "",
      robots: "index, follow",
    }
  }
}

function getTrainers(data) {
  const authors = [];
  if (Array.isArray(data)) {
    data.forEach((author) => {
      const _author = {
        id: author._id,
        name: author.name,
        photo: author.photo,
        biography: author._rawBio,
        roles: author.roles,
        socials: author.socials,
      };
      authors.push(_author);
    });
  }

  return authors;
}

function getPacks(data){
  if(data.pricesSessions && data.pricesSessions.packs){
    return data.pricesSessions.packs;
  } else {
    return null;
  }
}