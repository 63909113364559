import "./team.scss";

import BlockContent from "@sanity/block-content-to-react";
import { Link } from "gatsby";
import SanityImage from "gatsby-plugin-sanity-image";
// import Slider from "infinite-react-carousel";
import * as React from "react";
import { createRef, PropsWithChildren, useLayoutEffect } from "react";
import ReactDOM from "react-dom";
import ScrollContainer from "react-indiana-drag-scroll";

import { Author } from "../../../contexts/blog/domain/author";

interface PropsTeam extends PropsWithChildren<any> {
  authors: Author[];
}

const Team: React.FC<PropsTeam> = (props: PropsTeam) => {
  const { authors } = props;
  const authors_render = [];
  const dots_render = [];
  const sliderTeam = createRef(null);
  let withCard;

  useLayoutEffect(() => {
    function resize() {
      const widthWindow = window.innerWidth;
      withCard =
        document.getElementsByClassName("m-team-cards__item")[0].clientWidth;
      const withScrollContainer =
        document.getElementsByClassName("m-team-cards")[0].clientWidth;

      const cardsInViewPort = widthWindow / withCard;

      // const width = window.innerWidth / 552 + 1;
    }
    resize();
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  const move = (moveLong: number) => {
    const rdSlider = ReactDOM.findDOMNode(sliderTeam.current);
    rdSlider.scrollTo(moveLong * withCard, 0);
  };

  authors.forEach((author, index) => {
    dots_render.push(
      <span onClick={() => move(index)} key={author.id}>
        O
      </span>
    );
    authors_render.push(
      <div id={author.id} className={"m-team-cards__item"} key={author.id}>
        <SanityImage
          {...author.photo}
          alt={author.name}
          className={"a-image--rounded a-team__image"}
          width={552}
        />
        <h3>{author.name}</h3>
        <BlockContent blocks={author.biography} />
      </div>
    );
  });
  authors_render.push(
    <div className={"m-team-cards__item"} key={"empty-card-team"}></div>
  );
  // function resize(e) {
  //   const width = e.target.innerWidth;
  //   switch (true) {
  //     case width < 552:
  //       setSlidesToShow(1);
  //       break;
  //     case width < 800:
  //       setSlidesToShow(1.5);
  //       break;
  //     case width < 1500:
  //       setSlidesToShow(2.5);
  //       break;
  //     default:
  //       setSlidesToShow(3.5);
  //       break;
  //   }
  // }

  // useLayoutEffect(() => {
  //   let width = window.innerWidth / 552 + 1;
  //   if (width > 3) {
  //     width = 2.5;
  //   }
  //   let intWidth = 1;
  //   if (window.innerWidth > 552) {
  //     intWidth = Number(width.toFixed(1));
  //   }
  //   setTimeout(() => {
  //     setSlidesToShow(intWidth);
  //   }, 100);
  // }, []);

  return (
    <section className={"o-team"}>
      <h2 className={"a-team-title"}>
        Conócenos en el centro Obelion Fisioterapia
      </h2>
      <div className={"m-navigation-slider"}>{dots_render}</div>
      <ScrollContainer
        className="scroll-container m-team-cards"
        ref={sliderTeam}
      >
        <div className={"scroll-container__content"}>{authors_render}</div>
      </ScrollContainer>
      {/*<Slider*/}
      {/*  dots*/}
      {/*  slidesToShow={slidesToShow}*/}
      {/*  arrows={false}*/}
      {/*  ref={sliderRef}*/}
      {/*  className={"slider"}*/}
      {/*  onResize={(e) => {*/}
      {/*    resize(e);*/}
      {/*  }}*/}
      {/*>*/}
      {/*  {authors_render}*/}
      {/*</Slider>*/}
    </section>
  );
};

export default Team;
